<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Presents Preclinical Data on GV20-0251 for Solid Tumors Targeting IGSF8 at AACR Annual Meeting 2024 
      </div>
      <div class="time">
        Apr 8, 2024
      </div>
      <div class="content">
        <p>
          <i>-GV20-0251 is the first ever AI-designed antibody against an AI-predicated target to advance into the clinic-</i>
        </p>
        <p>
          Cambridge, Massachusetts – April 8, 2024 – GV20 Therapeutics, a clinical-stage biotechnology company integrating AI, genomics, and disease biology to create next-generation antibody therapeutics for cancer, today presented preclinical data on it GV20-0251 program during an oral presentation at the American Association of Cancer Research (AACR) Annual Meeting 2024 in San Diego, CA.  
        </p>
        <p>
          “I am honored to showcase our novel technology platform and present the previously unknown immune function of IGSF8 at AACR 2024. GV20-0251 is the first ever AI-designed antibody against an AI-predicated target to advance into the clinic and can potentially bring significant benefits to patients with solid tumors,” said Shirley Liu, CEO of GV20 Therapeutics. “We have made great progress on GV20-0251 Phase I dose escalation and look forward to advancing into monotherapy cohort expansion and combination studies later this year.”
        </p>
        <p>
          The oral presentation at AACR included an overview on the company’s novel discovery and development process of its lead antibody, GV20-0251, which antagonizes a novel cancer immunotherapy target, IGSF8. Many cancer patients’ immune systems make anti-PD1/anti-PDL1 antibodies, however the patients do not make enough and have the wrong IgA Fc to completely kill the tumors. GV20 uses tens of thousands of tumors as experimental systems and leverages artificial intelligence (AI) to mine big data from large tumor profiles to predict the antibodies in patient tumors that can be developed into cancer drugs. Through this modeling, GV20 discovered that IGSF8 is a novel innate immune checkpoint highly expressed on malignant cells with antigen presentation defects. Anti-IGSF8 enhances NK cell killing, antigen presentation, and turns immune-cold tumors hot. In addition, anti-IGSF8 alone or in combination with anti-PD1 inhibits tumor growth in multiple models, including some known to resist anti-PD1 therapy.  
        </p>
        <p>
          GV20’s in-house STEAD platform (Simultaneous Target Evaluation and Antibody Discovery), which integrates AI, genomics, and disease biology, can not only identify novel drug targets, but also create next-generation therapies against these targets, like GV20-0251. The ongoing Phase I study in solid tumors (NCT05669430) is advancing and GV20-0251 is demonstrating promising early clinical activities.
        </p>
        <p>
          <div class="font-weight-bold">
            Details of the oral presentation at AACR are below:
          </div>
          <b>Title:</b> “IGSF8 is a novel innate immune checkpoint and cancer immunotherapy target”<br>
          <b>Session Type:</b> Minisymposium<br>
          <b>Session Category:</b> Immunology<br>
          <b>Session Title:</b> Immune Targets and Therapies<br>
          <b>Session Date/Time:</b> Monday Apr 8, 2024, 2:30 PM - 4:30 PM<br>
          <b>Abstract Presentation Number:</b> 3914<br>
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          At GV20 Therapeutics, our mission is to develop a differentiated pipeline of innovative antibody therapeutics to transform cancer treatment.  We leverage our in-house STEAD platform (Simultaneous Target Evaluation and Antibody Discovery), which integrates AI, genomics, and disease biology to identify novel drug targets and create next-generation therapies against these targets.  Our lead program, GV20-0251, a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody targeting a novel innate immune checkpoint IGSF8, is in a multi-center Phase I clinical trial in patients with advanced solid tumors (<a style="color:#2d8cf0;" href="https://classic.clinicaltrials.gov/ct2/show/NCT05669430">NCT05669430</a>). GV20 is headquartered in Cambridge, MA.
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://gv20tx.com/">https://gv20tx.com/</a> and follow the company on LinkedIn.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>
        <p>
          <div class="font-weight-bold">
            Investor and Media Contact
          </div>
          Amy Conrad<br>
          Juniper Point<br>
          amy@juniper-point.com
        </p>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
